import * as XLSX from 'xlsx';

function excelSerialDateToISODate(serial) {
    const excelEpoch = new Date(1900, 0, 2); // December 31, 1899
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = 24 * 60 * 60 * 1000;
    const delta = serial * 24 * 60 * 60 * 1000;

    let parsedDate = new Date(excelEpochAsUnixTimestamp + delta - missingLeapYearDay);

    // Fix the 1900 leap year bug by subtracting one day if date is post 1900-Feb-28
    if (serial > 60) {
        parsedDate = new Date(parsedDate.getTime() - missingLeapYearDay);
    }

    return parsedDate.toISOString().slice(0, 10); // YYYY-MM-DD
}

function fetchData(selectedName = '', selectedAffiliation = '', startDate = '', endDate = '', topic = '') {

    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch('/Data/FedSpeak_Complete.xlsx', {
                headers: { 'Content-Type': 'arraybuffer' },
            });
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            // Transform data
            const transformedData = jsonData.reduce((acc, item, index) => {

                const date = excelSerialDateToISODate(item.Date);

                // Date filter
                if ((startDate && date < startDate) || (endDate && date > endDate)) {
                    return acc;
                }

                const originalName = item['Speaker Name'];
                const isFOMC = originalName.includes('Federal Open Market Committee');
                const name = isFOMC ? 'FOMC' : originalName;

                // Name filter
                if (selectedName && !name.includes(selectedName)) {
                    return acc;
                }

                const isPresident = item['Speaker Title'] && item['Speaker Title'].toLowerCase().includes('president');
                const isVotingMember = item['Voting Member'] === 'Yes';

                const imageUrl = isFOMC ? '/Imgs/FOMC.png' : `/Imgs/${name.split(', ').reverse().join('_')}.jpg`;
                const formattedName = isFOMC ? originalName : name.split(', ').reverse().join(' ');
                const titleWithAffiliation = isFOMC ? '' :
                    item['Speaker Title'] && item['Speaker Affiliation']
                    ? `${item['Speaker Title']}, ${item['Speaker Affiliation']}`
                    : item['Speaker Title'] || item['Speaker Affiliation'] || '';

                let affiliations = [];
                if (isFOMC) {
                    affiliations = ['Federal Open Market Committee']; // Exclusive affiliation for FOMC
                } else {
                    if (isVotingMember) affiliations.push('Federal Open Market Committee');
                    if (isPresident) affiliations.push('Regional Banks');
                    if (!isPresident) affiliations.push('Federal Reserve Board');
                }

                // Affiliation filter
                if (selectedAffiliation && !affiliations.includes(selectedAffiliation)) {
                    return acc;
                }

                let remark = '';
                if (topic === 'rates' && item['Rate Remarks'] !== 'N/A') {
                    remark = item['Rate Remarks'];
                } else if (topic === 'inflation' && item['Inflation Remarks'] !== 'N/A') {
                    remark = item['Inflation Remarks'];
                } else if (topic === 'labor-market' && item['Labor Market Remarks'] !== 'N/A') {
                    remark = item['Labor Market Remarks'];
                } else if (!topic) {
                    const remarks = [];
                    if (item['Rate Remarks'] !== 'N/A') remarks.push(item['Rate Remarks']);
                    if (item['Inflation Remarks'] !== 'N/A') remarks.push(item['Inflation Remarks']);
                    if (item['Labor Market Remarks'] !== 'N/A') remarks.push(item['Labor Market Remarks']);
                    if (item['Other Remarks']) remarks.push(item['Other Remarks']);
                    remark = remarks.join('');
                }

                if (remark) {
                    acc.push({
                        id: String(index + 1),
                        date: date,
                        name: name,
                        formattedName: formattedName,
                        title: titleWithAffiliation,
                        remark: remark,
                        url: item['URL'],
                        voting_member: isVotingMember ? '*' : '',
                        imageUrl: imageUrl,
                        affiliation: affiliations, // Join multiple affiliations into a string if necessary
                        rateRemark: item['Rate Remarks'] || '',
                        inflationRemark: item['Inflation Remarks'] || '',
                        laborMarketRemark: item['Labor Market Remarks'] || '',
                        otherRemark: item['Other Remarks'] || ''
                    });
                }
                return acc;
            }, []);

            console.log("Filtered Data:", transformedData);
            console.log(transformedData.length)
            resolve({data: transformedData, count: transformedData.length});
        } catch (error) {
            console.error("Error fetching or processing Excel file:", error);
            reject(error);
        }
    });
}

export default fetchData;