import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { useTable, useFilters, useSortBy } from 'react-table';
import resumeData from './resumeData';  // Import the resume data
import './ProfilePage.css';

const ProfilePage = () => {
  const { name } = useParams();
  const [comments, setComments] = useState([]);
  const [officialImage, setOfficialImage] = useState(null);
  const [officialRole, setOfficialRole] = useState(null);
  const [isResumeExpanded, setIsResumeExpanded] = useState(true);
  const [isRemarksExpanded, setIsRemarksExpanded] = useState(true);
  const [loading, setLoading] = useState(true);  // Add loading state

  const capitalize = (str) => {
    return str.split("'").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join("'");
  };

  let displayName, finalFormattedName, imageName, isFOMC;
  if (name.toLowerCase().includes('federal_open_market_committee')) {
    displayName = 'Federal Open Market Committee';
    finalFormattedName = 'FOMC';
    imageName = 'FOMC_Blue.png';  // Set a default image for FOMC
    isFOMC = true;
  } else {
    const formattedName = name.split('_').map(word => capitalize(word)).join(' ');
    const nameParts = formattedName.split(' ');
    const lastName = nameParts.pop(); // Assume the last part is the last name
    const firstName = nameParts.join(' '); // Join the remaining parts for the first name
    displayName = `${firstName} ${lastName}`;
    finalFormattedName = `${lastName}, ${firstName}`;
    imageName = `${firstName}_${lastName}.jpg`;
    isFOMC = false;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/Data/FedSpeak_Complete.xlsx', {
          headers: { 'Content-Type': 'arraybuffer' },
        });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const commentsData = jsonData
          .filter(item => {
            if (isFOMC) {
              return item['Speaker Name'].toLowerCase().includes('federal open market committee');
            }
            const excelName = item['Speaker Name'].toLowerCase();
            const formattedExcelName = excelName.replace(/,\s*/g, ', ').replace(/\s+/g, ' ').trim();
            const compareName = finalFormattedName.toLowerCase().replace(/,\s*/g, ', ').replace(/\s+/g, ' ').trim();
            return formattedExcelName === compareName;
          })
          .flatMap(item => {
            const date = excelSerialDateToISODate(item.Date);
            const source = item['URL'];
            const sourceType = item['Speaker Name'].toLowerCase().includes('minutes') ? 'Minutes' : 'Statement';

            // Remove bullet points and split into individual comments
            const rateRemarks = item['Rate Remarks'] ? item['Rate Remarks'].split(/•\s*/).filter(Boolean).map(remark => ({ remark, category: 'Rates' })) : [];
            const inflationRemarks = item['Inflation Remarks'] ? item['Inflation Remarks'].split(/•\s*/).filter(Boolean).map(remark => ({ remark, category: 'Inflation' })) : [];
            const laborMarketRemarks = item['Labor Market Remarks'] ? item['Labor Market Remarks'].split(/•\s*/).filter(Boolean).map(remark => ({ remark, category: 'Labor Market' })) : [];
            const otherRemarks = item['Other Remarks'] ? item['Other Remarks'].split(/•\s*/).filter(Boolean).map(remark => ({ remark, category: 'Other' })) : [];

            // Combine all remarks into one array
            const allRemarks = [...rateRemarks, ...inflationRemarks, ...laborMarketRemarks, ...otherRemarks];

            return allRemarks
              .filter(({ remark }) => remark !== 'N/A')  // Exclude 'N/A' comments
              .map(({ remark, category }) => ({
                date,
                remark,
                category,
                source,
                sourceType
              }));
          });

        setComments(commentsData);
        // setOfficialImage(`/Imgs/${imageName}`)

        // Extract current role information from resumeData
        const resume = resumeData[name.toLowerCase()];
        if (resume && resume.currentRole) {
          setOfficialRole(resume.currentRole);
        }

        setLoading(false);  // Data fetching complete, set loading to false

      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);  // Error occurred, still set loading to false
      }
    };

    // Preload the image
    const preloadImage = new Image();
    preloadImage.src = `/Imgs/${imageName}`;
    preloadImage.onload = () => {
        setOfficialImage(`/Imgs/${imageName}`);
        fetchData();  // Fetch data after image has been preloaded
    };

  }, [finalFormattedName, imageName]);

  const handleExportClick = () => {
    const data = comments.map(comment => {
      if (isFOMC) {
        return {
          Date: comment.date,
          Type: comment.sourceType,
          Comment: comment.remark,
          Category: comment.category,
          Source: comment.source,
        };
      } else {
        return {
          Date: comment.date,
          Comment: comment.remark,
          Category: comment.category,
          Source: comment.source,
        };
      }
    });
  
    // Ensure all objects have the same keys to avoid Excel issues
    const keys = isFOMC
      ? ['Date', 'Type', 'Comment', 'Category', 'Source']
      : ['Date', 'Comment', 'Category', 'Source'];
  
    // Create worksheet with explicit header
    const worksheet = XLSX.utils.json_to_sheet([], { header: keys });
  
    // Append data to worksheet
    XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: 'A2' });
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Comments');
  
    // Write the workbook to file
    XLSX.writeFile(workbook, `${displayName.replace(/\s+/g, '')}_comments.xlsx`);
  };

  // Utility function to calculate the term in years
  const calculateTermInYears = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();
    const differenceInMilliseconds = end - start;
    const differenceInYears = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return differenceInYears.toFixed(0); // Format to 1 decimal place
  };  

  const getTermDisplay = (dates) => {
    if (dates.startsWith('Since ')) {
      const startDate = dates.replace('Since ', '');
      const termLength = calculateTermInYears(startDate, '');
      return `${dates} (${termLength} years)`;
    } else {
      const [startDate, endDate] = dates.split(' – ');
      const termLength = calculateTermInYears(startDate, endDate);
      return `${startDate} – ${endDate} (${termLength} years)`;
    }
  };
  
  // Define table columns
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Date',
        accessor: 'date',
        disableFilters: true,  // Disable filtering for the Date column
        sortType: 'basic',  // Only the Date column can be sorted
      },
      {
        Header: 'Comment',
        accessor: 'remark',
        disableFilters: true,
      },
      {
        Header: 'Category',
        accessor: 'category',
        Filter: SelectColumnFilter,
        filter: 'includes',  // Only the Category column can be filtered
        disableSortBy: true,
      },
      {
        Header: 'Source',
        accessor: 'source',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => <a href={value} target="_blank" rel="noopener noreferrer">View Source</a>,
      },
    ];

    // Add the Source Type column only for FOMC
    if (isFOMC) {
      baseColumns.splice(1, 0, {
        Header: 'Type',
        accessor: 'sourceType',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      });
    }

    return baseColumns;
  }, [isFOMC]);

  const data = useMemo(() => comments, [comments]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'date',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );

  // Get resume data for the current official
  const officialResume = resumeData[name.toLowerCase()];

  // Display nothing until everything is fully loaded
  if (loading) {
    return null;  // Render nothing while loading
  }

  return (
    <div className="profile-page">
      <div className="profile-header">
        {!officialImage ? (
          <div className="skeleton official-image"></div>
        ) : (
          <img src={officialImage} alt={''} className={isFOMC ? 'fomc-image' : 'official-image'} onLoad={(e) => e.target.classList.add('official-image-loaded')} />
        )}
        <h1 className="profile-name">{displayName}</h1>
        {!isFOMC && officialRole && (
          <div className="profile-role">
            <p className="role">{officialRole.role}</p>
            <p className="term">
              {getTermDisplay(officialRole.dates)}
            </p>
          </div>
        )}
      </div>
      
      {/* Resume Section */}
      {officialResume && (
        <div className="accordion-section">
          <div className="accordion-header" onClick={() => setIsResumeExpanded(!isResumeExpanded)}>
            <h2>Background</h2>
            <span className="icon">{isResumeExpanded ? '−' : '+'}</span>
          </div>
          {isResumeExpanded && (
            <div className="accordion-content">
              {/* Professional Experience Table */}
              <h3>Professional</h3>
              <table>
                <tbody>
                  {officialResume.professional.map((item, index) => (
                    <tr key={index}>
                      <td>{item.role}</td>
                      <td>{item.dates}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Education Table */}
              <h3>Education</h3>
              <table>
                <tbody>
                  {officialResume.education.map((item, index) => (
                    <tr key={index}>
                      <td>{item.role}</td>
                      <td>{item.dates}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Sources */}
              <h3>Sources</h3>
              <div className="sources-list">
                {officialResume.sources.map((source, index) => (
                  <div key={index}>
                    <a href={source} target="_blank" rel="noopener noreferrer">{source}</a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Public Remarks Section */}
      <div className="accordion-section">
        <div className="accordion-header" onClick={() => setIsRemarksExpanded(!isRemarksExpanded)}>
          <h2>Public Comments</h2>
          <span className="icon">{isRemarksExpanded ? '−' : '+'}</span>
        </div>
        {isRemarksExpanded && (
          <div className="accordion-content">
            <div className="export-button-profile-container">
              <button className="export-button-profile" onClick={handleExportClick}>Export</button>
            </div>
            <div className="table-container"> {/* Add this line */}
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ▼'
                                : ' ▲'
                              : ''}
                          </span>
                          <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps({
                                className: cell.column.id === 'source' ? 'source-column' : cell.column.id === 'date' ? 'date-column' : '',
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> {/* Add this line */}
          </div>
        )}
      </div>
    </div>
  );  
};

// Utility function to convert Excel serial date to ISO date
const excelSerialDateToISODate = (serial) => {
  const excelEpoch = new Date(1900, 0, 1);
  const excelEpochAsUnixTimestamp = excelEpoch.getTime();
  const missingLeapYearDay = 24 * 60 * 60 * 1000;
  const delta = serial * 24 * 60 * 60 * 1000;

  let parsedDate = new Date(excelEpochAsUnixTimestamp + delta - missingLeapYearDay);

  if (serial > 60) {
    parsedDate = new Date(parsedDate.getTime() - missingLeapYearDay);
  }

  return parsedDate.toISOString().slice(0, 10);
};

// Select column filter for Category or Source Type
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {

  // Define options based on column id
  const options = id === 'category'
    ? ['Rates', 'Inflation', 'Labor Market', 'Other']
    : ['Minutes', 'Statement'];

  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}


export default ProfilePage;