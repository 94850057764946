import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <div className="about-container">
        <p className="fed-watchers">
            <em>You're welcome, Fed watchers.</em>
        </p>
        <p className="about-description">
          This site is a public service intended to provide the most comprehensive collection of Federal Reserve discourse online.<br/><br/>
          
          <em>"But given the outsized influence of the Federal Open Market Committee on the global economy, and the extent to which
            Federal Reserve communications are scrutinized for insights into US monetary policy, doesn't the Fed maintain its own 
            central repository of public communications, rendering this site unnecessary and redundant?"</em><br/><br/>
          
          Lol, no. Apparently, that doesn't fall under the scope of the Fed's commitment to "transparency." The Federal Reserve <em>Board</em> does
          publish most public remarks made by Board members. But each of the 12 regional bank presidents implements their own 
          system for promulgating their public comments, with varying degrees of consistency and reliability.<br/><br/>
          
          In short, I'm the only one doing this (as far as I'm aware). If you know of another public aggregator of Fedspeak, let me
          know so I can free myself from this heavy burden.<br/><br/>
          
          The maintenance of this collection is only semi-automated, which means this effort is only semi-sustainable. If you 
          benefit from this resource in your analysis or reporting, please acknowledge its role and spread the word. 
          It'll make all this work feel a little less thankless.<br/><br/>

          For more information, or to buy this website and take it off my hands, email <a href="mailto:info@fedsaidwhat.com" className="about-link">info@fedsaidwhat.com</a>.
        </p>
      </div>
    </div>
  );
}

export default About;
