// src/Analysis.js
import React from 'react';
import analysisIcon from './Imgs/ComingSoon.png';
import './Analysis.css';  // Ensure you create and import the CSS file

const Analysis = () => {
  return (
    <div className="analysis-container">
      <div className="analysis-page">
        <img src={analysisIcon} alt="" className="coming-soon-image" />
      </div>
    </div>
  );
};

export default Analysis;