import React, { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import './App.css';

import headerLogo from './Imgs/FedSaidWhat_Logo_trans.png';
import fedLogo from './Imgs/FOMC.png';
import methodIcon from './Imgs/MethodologyIcon.png';
import homeIcon from './Imgs/HomeIcon.png';
import aboutIcon from './Imgs/AboutIcon.png';
import aboutLogo from './Imgs/AboutFedSaidWhat_Logo_trans.png'
import subIcon from './Imgs/SubscribeIcon.png';
import peopleIcon from './Imgs/PeopleIcon.png';
import analysisIcon from './Imgs/AnalysisIcon.png';
import fdbIcon from './Imgs/FDB_Screenshot.png';

import profileHomeIcon from './Imgs/HomeIconBlue.png';
import profileAboutIcon from './Imgs/AboutIconBlue.png';
import profileAnalysisIcon from './Imgs/AnalysisIconBlue.png';
import profilePeopleIcon from './Imgs/PeopleIconBlue.png';

import Timeline from './Timeline';
import fetchData from './fetchData';
import About from './About';
import Analysis from './Analysis';
import ProfilePage from './ProfilePage';
import Unsubscribe from './Unsubscribe';

import * as XLSX from 'xlsx';


const detectBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('firefox') > -1) {
    document.body.classList.add('firefox');
  } else if (userAgent.indexOf('edg') > -1) {
    document.body.classList.add('edge');
  } else if (userAgent.indexOf('chrome') > -1) {
    if (userAgent.indexOf('safari') > -1) {
      // This is to detect Chrome running on Safari engines
      document.body.classList.add('chrome');
    }
  } else if (userAgent.indexOf('safari') > -1) {
    document.body.classList.add('safari');
  }
};

// Header component
const Header = ({ onMethodologyClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add this line
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // New state for success modal
  const [email, setEmail] = useState('');
  const location = useLocation();
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const peopleDropdownRef = useRef(null);
  const [isPeopleDropdownOpen, setIsPeopleDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleHamburger = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  const togglePeopleDropdown = () => {
    setIsPeopleDropdownOpen(!isPeopleDropdownOpen);
  };

  const handleMenuMethodologyClick = () => {
    setIsMenuOpen(false);
    onMethodologyClick(); // Call the passed down function
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
    if (hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
      setIsHamburgerOpen(false);
    }
    if (peopleDropdownRef.current && !peopleDropdownRef.current.contains(event.target)) {
      setIsPeopleDropdownOpen(false);
    }
  };

  const handleSubscribeClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsSuccessModalOpen(false);
    setEmail(''); // Reset email state when modal is closed
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent page refresh

    // EmailJS logic to send an email
    emailjs.send('service_zpd614m', 'template_7eqgaw2', { user_email: email }, 'UuoYoxLflsEMlH4Tv')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setIsSuccessModalOpen(true);
      }, (error) => {
        console.error('FAILED...', error);
      });

    setEmail('');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // List of people
  const peopleList = [
    "Barkin, Tom",
    "Barr, Michael",
    "Bostic, Raphael",
    "Bowman, Michelle",
    "Collins, Susan",
    "Cook, Lisa",
    "Daly, Mary",
    "FOMC",
    "Goolsbee, Austan",
    "Harker, Patrick",
    "Jefferson, Philip",
    "Kashkari, Neel",
    "Kugler, Adriana",
    "Logan, Lorie",
    "Mester, Loretta",
    "Musalem, Alberto",
    "O'Neill, Kathleen",
    "Powell, Jerome",
    "Schmid, Jeffrey",
    "Waller, Christopher",
    "Williams, John"
  ];

  const isNotAboutPage = location.pathname !== '/about';
  const isNotAnalysisPage = location.pathname !== '/analysis';
  const isNotUnsubscribePage = location.pathname !== '/unsubscribe';
  const isProfilePage = location.pathname.startsWith('/profile');

  return (
    <header className="app-header">
      {isNotUnsubscribePage && (
        <>
          <div className="nav-bar">
            <div className="nav-links">
              <Link to="/" className="nav-link">
                <img src={isProfilePage ? profileHomeIcon : homeIcon} alt="" className="nav-icon1" />  {/* Conditional icon */}
              </Link>
              <Link to="/about" className="nav-link">
                <img src={isProfilePage ? profileAboutIcon : aboutIcon} alt="" className="nav-icon2" />  {/* Conditional icon */}
              </Link>
              <div className="nav-link" onClick={togglePeopleDropdown} ref={peopleDropdownRef}>
                <img src={isProfilePage ? profilePeopleIcon : peopleIcon} alt="" className="nav-icon4" />  {/* Conditional icon */}
                {isPeopleDropdownOpen && (
                  <div className="dropdown-menu">
                    {peopleList.map((person, index) => {
                      let reformattedName
                      if (person === 'FOMC') {
                        reformattedName = 'federal_open_market_committee';
                      } else {
                        const formattedName = person.split(', ').reverse().join(' ');
                        reformattedName = formattedName.toLowerCase().replace(/ /g, '_');
                      }
                      return (
                        <Link
                          key={index}
                          to={`/profile/${encodeURIComponent(reformattedName)}`}
                          className="dropdown-item"
                          onClick={() => setIsPeopleDropdownOpen(false)}
                        >
                          {person}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
              <Link to="/analysis" className="nav-link">
                <img src={isProfilePage ? profileAnalysisIcon : analysisIcon} alt="" className="nav-icon3" />  {/* Conditional icon */}
              </Link>
            </div>
            <div className={`hamburger-menu ${isProfilePage ? 'hamburger-menu-profile' : ''}`} onClick={toggleHamburger} ref={hamburgerRef}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
          {isHamburgerOpen && (
            <div className="hamburger-content" ref={hamburgerRef}>
              <Link to="/" className="dropdown-link" onClick={toggleHamburger}>Home</Link>
              <Link to="/about" className="dropdown-link" onClick={toggleHamburger}>About</Link>
              <div className="dropdown-link" onClick={togglePeopleDropdown} ref={peopleDropdownRef}>
                People
                {isPeopleDropdownOpen && (
                  <div className="people-dropdown-menu">
                    {peopleList.map((person, index) => {
                      let reformattedName;
                      if (person === 'FOMC') {
                        reformattedName = 'federal_open_market_committee';
                      } else {
                        const formattedName = person.split(', ').reverse().join(' ');
                        reformattedName = formattedName.toLowerCase().replace(/ /g, '_');
                      }
                      return (
                        <Link
                          key={index}
                          to={`/profile/${encodeURIComponent(reformattedName)}`}
                          className="dropdown-item"
                          onClick={() => {
                            setIsPeopleDropdownOpen(false);
                            setIsHamburgerOpen(false);
                          }}
                        >
                          {person}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
              <Link to="/analysis" className="dropdown-link" onClick={toggleHamburger}>Analysis</Link>
            </div>
          )}
        </>
      )}
  
      {/* Conditionally render elements based on the route */}
      {isNotAnalysisPage && isNotUnsubscribePage && !/^\/profile\/.*/.test(location.pathname) && (        <>
          <div className="fixed-menu-button">
            <div className="menu-icon" onClick={toggleMenu} ref={menuRef}>
              {isNotAboutPage && (
                <img src={fedLogo} alt="" className="fed-logo-menu" title="Menu"/>
              )}
            </div>
            {isMenuOpen && isNotAboutPage && (
              <div className="menu-content" ref={menuRef}>
                <Link to="#" onClick={handleSubscribeClick}>Subscribe</Link>
                <Link to="/" onClick={handleMenuMethodologyClick}>Methodology</Link>
              </div>
            )}
          </div>
          <div className="icon-container">
            {isNotAboutPage && (
              <Link to="#" onClick={handleSubscribeClick}>
                <img src={fedLogo} alt="" className="fed-logo" />
                <img src={subIcon} alt="" className="sub-icon" />
              </Link>
            )}
          </div>
          {isNotAboutPage ? (
            <Link to="/" title="Home">
              <img src={headerLogo} alt="" className="app-logo" />
            </Link>
          ) : (
            <Link to="/" title="Home">
              <img src={aboutLogo} alt="" className="app-logo" />
            </Link>
          )}
          {isNotAboutPage && (
            <div className="icon-container" onClick={onMethodologyClick}>
              <img src={fedLogo} alt="" className="fed-logo" />
              <img src={methodIcon} alt="Methodology" className="methodology-icon" />
            </div>
          )}
          {isNotAboutPage && (
            <p className="tagline">Watching the Fed since April 2024</p>
          )}
        </>
      )}
  
      {location.pathname === '/unsubscribe' && (
        <Link to="/" title="Home">
          <img src={headerLogo} alt="" className="app-logo" />
        </Link>
      )}
  
      {isModalOpen && (
        <div className="subscribe-modal">
          <div className={`subscribe-modal-content ${isSuccessModalOpen ? 'hidden' : ''}`}>
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <form className="subscribe-form" onSubmit={handleSubmit}>
              <h2>Can't get enough Fedspeak?</h2>
              <p>Subscribe to the <i>Fedspeak Daily Brief</i> and get the Fed’s latest pronouncements delivered straight to your inbox.</p>
              <img src={fdbIcon} alt="" className="fdb-screenshot" />
              <p>Enter your email below to join the fun!</p>
              <div className="form-group">
                <input type="email" id="user_email" name="user_email" value={email} onChange={handleEmailChange} required />
                <button type="submit">Subscribe</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isSuccessModalOpen && (
        <div className="success-modal">
          <div className="success-modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Thanks for subscribing :)</h2>
          </div>
        </div>
      )}
    </header>
  );
  };  


// Main App component
function App() {

  const [events, setEvents] = useState([]);
  const [count, setCount] = useState(0); 
  const [showAll, setShowAll] = useState(true);
  const [selectedName, setSelectedName] = useState('');
  const [selectedAffiliation, setSelectedAffiliation] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [zoomLevel, setZoomLevel] = useState(8); // Default zoom level

  const [showMethodology, setShowMethodology] = useState(false);
  const methodologyRef = useRef(null);

  // Calculate initial start and end dates
  const today = new Date();
  const initialEndDate = today.toISOString().slice(0, 10);
  const initialStartDate = new Date(today.setDate(today.getDate() - 30)).toISOString().slice(0, 10);

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const handleNameChange = (name) => {
    setSelectedName(name);
    if (name) {  // Only reset affiliation if a name is selected
      setSelectedAffiliation('');
    }
    updateEvents(name, '', selectedTopic, startDate, endDate);
  };
  
  const handleAffiliationChange = (affiliation) => {
    setSelectedAffiliation(affiliation);
    if (affiliation) {  // Only reset name if an affiliation is selected
      setSelectedName('');
    }
    updateEvents('', affiliation, selectedTopic, startDate, endDate);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    updateEvents(selectedName, selectedAffiliation, selectedTopic, start, end);
  };

  const handleShowAllChange = (isChecked) => {
    setShowAll(isChecked);
    if (isChecked) {
      updateEvents(selectedName, selectedAffiliation, selectedTopic, startDate, endDate);
    }
  };

  const handleTopicChange = (topic) => {  // New function for handling topic changes
    setSelectedTopic(topic);
    updateEvents(selectedName, selectedAffiliation, topic, startDate, endDate);
  };
  
  const handleExportClick = () => {
    fetchData(selectedName, selectedAffiliation, startDate, endDate, selectedTopic).then(result => {
        const stripBulletPoints = (text) => {
            return text.replace(/^\s*•\s*/gm, '').trim();
        };

        const determinePrimaryAffiliation = (affiliations) => {
            if (affiliations.includes('Federal Reserve Board')) {
                return 'Federal Reserve Board';
            } else if (affiliations.includes('Regional Banks')) {
                return 'Regional Banks';
            } else {
                return 'N/A';
            }
        };

        // Determine default dates
        const defaultStartDate = '2024-04-01';
        const today = new Date();
        const defaultEndDate = today.toISOString().slice(0, 10);

        // Use default dates if necessary
        const effectiveStartDate = startDate || defaultStartDate;
        const effectiveEndDate = endDate || defaultEndDate;

        const allData = result.data.map(event => {
            const primaryAffiliation = determinePrimaryAffiliation(event.affiliation);
            const isFOMC = event.affiliation.includes('Federal Open Market Committee') ? 'Yes' : 'No';

            return {
                Date: event.date,
                Name: event.name,
                Title: event.title || 'N/A',
                Affiliation: primaryAffiliation,
                FOMC_Member: isFOMC,
                Rate_Remarks: stripBulletPoints(event.rateRemark || ''),
                Inflation_Remarks: stripBulletPoints(event.inflationRemark || ''),
                Labor_Market_Remarks: stripBulletPoints(event.laborMarketRemark || ''),
                Other_Remarks: stripBulletPoints(event.otherRemark || ''),
                Source_URL: event.url,
            };
        });

        const baseFields = ['Date', 'Name', 'Title', 'Affiliation', 'FOMC_Member'];
        let relevantFields;

        switch (selectedTopic) {
            case 'rates':
                relevantFields = [...baseFields, 'Rate_Remarks', 'Source_URL'];
                break;
            case 'inflation':
                relevantFields = [...baseFields, 'Inflation_Remarks', 'Source_URL'];
                break;
            case 'labor-market':
                relevantFields = [...baseFields, 'Labor_Market_Remarks', 'Source_URL'];
                break;
            default:
                relevantFields = [...baseFields, 'Rate_Remarks', 'Inflation_Remarks', 'Labor_Market_Remarks', 'Other_Remarks', 'Source_URL'];
                break;
        }

        const data = allData.map(event => {
            const filteredEvent = {};
            relevantFields.forEach(field => {
                filteredEvent[field] = event[field];
            });
            return filteredEvent;
        });

        const worksheet = XLSX.utils.json_to_sheet(data);

        // Add hyperlinks and style to the URL column
        const urlColumnIndex = relevantFields.indexOf('Source_URL');
        data.forEach((event, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: index + 1, c: urlColumnIndex });
            if (event.Source_URL !== 'N/A') {
                worksheet[cellAddress].l = { Target: event.Source_URL, Tooltip: 'View source' };
            }
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'FedSaidWhat');
        const filename = `FedSaidWhat_${effectiveStartDate}_to_${effectiveEndDate}.xlsx`;

        XLSX.writeFile(workbook, filename);
    }).catch(error => {
        console.error("Failed to export data:", error);
    });
  };

  const updateEvents = (name, affiliation, topic, start, end) => {
    fetchData(name, affiliation, start, end, topic).then(result => {
      setEvents(result.data);
      setCount(result.count);  // Update the count state
      if (showAll) {
        setShowAll(false);
        setTimeout(() => setShowAll(true), 0);
      }
    }).catch(error => {
        console.error("Failed to fetch data:", error);
    });
  };

  const handleMethodologyClick = () => {
    setShowMethodology(true);
    setTimeout(() => {
      if (methodologyRef.current) {
        methodologyRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };
  
  const handleHideMethodologyClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (window.innerWidth > 680) { // Check if the screen is wider than 680px
        setTimeout(() => {
            setShowMethodology(false);
        }, 1000); // Adjust the timeout as needed to match the scroll duration
    } else {
        setShowMethodology(false); // No timeout for narrow screens
    }
  };

  const handleZoomChange = (event) => {
    setZoomLevel(parseFloat(event.target.value));
  };
  
  useEffect(() => {
    updateEvents(selectedName, selectedAffiliation, selectedTopic, startDate, endDate);
  }, [selectedName, selectedAffiliation, startDate, endDate, selectedTopic]);

  useEffect(() => {
    detectBrowser();
  }, []);

  // const isProfilePage = window.location.pathname.startsWith('/profile');

  return (
    <Router>
      <div className="app">
      {/* <div className={`app ${isProfilePage ? 'main-page-background' : 'profile-page-background'}`}> */}
        <Header onMethodologyClick={handleMethodologyClick} />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/analysis" element={<Analysis />} />
          <Route path="/profile/:name" element={<ProfilePage />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />

          <Route path="/" element={
            <>
              <FilterBar
                onShowAllChange={handleShowAllChange}
                onNameChange={handleNameChange}
                onAffiliationChange={handleAffiliationChange}
                onDateChange={handleDateChange}
                onTopicChange={handleTopicChange}
                selectedTopic={selectedTopic}
                selectedName={selectedName}
                selectedAffiliation={selectedAffiliation}
                startDate={startDate}
                endDate={endDate}
                count={count}
                showAll={showAll} // Pass the showAll state to FilterBar
                onExportClick={handleExportClick}
                zoomLevel={zoomLevel}
                onZoomChange={handleZoomChange}
              />
              <Timeline
                events={events}
                showAll={showAll}
                zoomLevel={zoomLevel} // Pass zoomLevel as prop
                onZoomChange={handleZoomChange} // Pass handleZoomChange as prop
              />
              <footer className="footer">
                <p>Copyright &copy; 2024 Fed Said What</p>
              </footer>
              {showMethodology && (
                <div ref={methodologyRef} className={`methodology-note ${showMethodology ? 'show' : ''}`}>
                  <div className="methodology-container">
                    <div className="methodology-header">
                    <button onClick={handleHideMethodologyClick} className="hide-link-button">Hide</button>
                      <h2>Methodology Note</h2>
                    </div>
                    <div className="methodology-content">
                      <h3>Objective</h3>
                      <p>'Fed Said What' is an interactive application that strives to provide users with a comprehensive* view of public 
                        statements made by key Federal Reserve officials—namely, the seven members of the Board of Governors and the presidents 
                        of the 12 Federal Reserve banks. It is designed for analysts, researchers, and the general public interested in 
                        discerning the current thinking and intent of monetary policymakers in the US.</p>
                      
                      <h3>Data Sourcing</h3>
                      <p>The application prioritizes sourcing the full remarks directly from official Federal Reserve speeches, publications, 
                        and statements. Approximately 10-15% of the time, a primary source is unavailable, typically because the event was not 
                        made publicly available. In such cases, the dataset cites the most detailed and credible account from a secondary source. 
                        When there is no single source that carries the full text of the remarks—such as when a speaker publishes their prepared 
                        remarks, but the subsequent Q&A session is reported by a third party—the source that provides the more comprehensive coverage 
                        of the remarks is selected. Note that in such cases, the selected quotations may come from any of the identified sources, 
                        not just the one cited in this application (see below for more details on the curation process).</p>
                      <p>The primary sources of data include the following:</p>
                      <ul>
                        <li>Official website of the Federal Reserve Board</li>
                        <li>Official websites of the 12 regional banks (and their associated social media channels)</li>
                        <li>Various media outlets that routinely cover the Fed (e.g., Reuters, Bloomberg)</li>
                      </ul>
                      
                      <h3>Data Curation</h3>
                      <p>The Federal Reserve has a dual mandate of achieving <strong>price stability</strong> and <strong>full 
                        employment</strong>. It accomplishes this mandate primarily via adjustments to the <strong>federal funds rate</strong>. 
                        As such, this application centers on officials’ key remarks on the topics
                        of <strong>Rates</strong>, <strong>Inflation</strong>, and the <strong>Labor Market</strong>. For each public appearance—defined as each instance 
                        of an official speaking at a public venue, to a given audience—the 1-2 most salient comments on each of these topics are 
                        extracted. This selection process involves a subjective assessment aimed at balancing representativeness and 
                        comprehensiveness. If the remarks do not focus on monetary policy or lack significant content on these topics, a 
                        concise summary is provided instead.</p>
                      
                      <h3>Updates and Maintenance</h3>
                      <p>This dataset is updated daily to include the latest communications from Federal Reserve officials. Occasionally, a 
                        primary source of the remarks is not immediately available. To address this, there is a one-week and two-week look-back 
                        period for Fed official appearances without primary sources to determine if a primary source has been uploaded after the 
                        fact. If no primary source is available within two weeks, the secondary source is considered the most authoritative 
                        account of the remarks.</p>
                      <div className="methodology-asterisk">
                        <p>*Comprehensive as of April 1, 2024</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          } />
        </Routes>
        <SpeedInsights />
        <Analytics />
      </div>
    </Router>
  );
}

// FilterBar component for the filtering section
function FilterBar({ 
  onShowAllChange, 
  onNameChange, 
  onAffiliationChange, 
  onDateChange, 
  onTopicChange, 
  selectedTopic, 
  selectedName, 
  selectedAffiliation, 
  startDate, 
  endDate, 
  count, 
  showAll,
  onExportClick,
  zoomLevel,
  onZoomChange
}) {
  const handleCheckboxChange = (event) => {
    onShowAllChange(event.target.checked);
  };

  const handleNameSelectChange = (event) => {
    onNameChange(event.target.value);
  };

  const handleStartDateChange = event => {
    onDateChange(event.target.value, endDate);
  };

  const handleEndDateChange = event => {
    onDateChange(startDate, event.target.value);
  };

  const handleAffiliationSelectChange = (event) => {
    if (event.target) {
      onAffiliationChange(event.target.value);
    } else {
      console.error('Event target is undefined');
    }
  };

  const handleTopicSelectChange = (event) => {  // New handler for topic select change
    onTopicChange(event.target.value);
  };

  const resultLabel = count === 0 ? "No results" : count === 1 ? "Show only result" : `Show all ${count} results`;

  return (
    <div className="filter-bar">
      {/* Non-date Filters */}
      <div className="filter-group">
        <div className="filter-item">
          <label htmlFor="topic-select">Topic</label>
          <select id="topic-select" value={selectedTopic} onChange={handleTopicSelectChange}>
            <option value="">- Select -</option>
            <option value="rates">Rates</option>
            <option value="inflation">Inflation</option>
            <option value="labor-market">Labor Market</option>
          </select>
        </div>
        <div className="filter-item">
          <label htmlFor="affil-select">Affiliation</label>
          <select id="affil-select" value={selectedAffiliation} onChange={handleAffiliationSelectChange} className="affiliation-select">
            <option value="">- Select -</option>
            <option value="Federal Open Market Committee">Federal Open Market Committee</option>
            <option value="Federal Reserve Board">Federal Reserve Board</option>
            <option value="Regional Banks">Regional Banks</option>
          </select>
        </div>
        <div className="filter-item">
          <label htmlFor="name-select">Name</label>
          <select id="name-select" value={selectedName} onChange={handleNameSelectChange}>
            <option value="">- Select -</option>
            <option value="FOMC">FOMC</option>
            <option value="Barkin, Tom">Barkin, Tom</option>
            <option value="Barr, Michael">Barr, Michael</option>
            <option value="Bostic, Raphael">Bostic, Raphael</option>
            <option value="Bowman, Michelle">Bowman, Michelle</option>
            <option value="Collins, Susan">Collins, Susan</option>
            <option value="Cook, Lisa">Cook, Lisa</option>
            <option value="Daly, Mary">Daly, Mary</option>
            <option value="Goolsbee, Austan">Goolsbee, Austan</option>
            <option value="Harker, Patrick">Harker, Patrick</option>
            <option value="Jefferson, Philip">Jefferson, Philip</option>
            <option value="Kashkari, Neel">Kashkari, Neel</option>
            <option value="Kugler, Adriana">Kugler, Adriana</option>
            <option value="Logan, Lorie">Logan, Lorie</option>
            <option value="Mester, Loretta">Mester, Loretta</option>
            <option value="Musalem, Alberto">Musalem, Alberto</option>
            <option value="O'Neill, Kathleen">O'Neill, Kathleen</option>
            <option value="Powell, Jerome">Powell, Jerome</option>
            <option value="Schmid, Jeffrey">Schmid, Jeffrey</option>
            <option value="Waller, Christopher">Waller, Christopher</option>
            <option value="Williams, John">Williams, John</option>
          </select>
        </div>
      </div>

      {/* Date Filters */}
      <div className="date-checkbox-group">
        <div className="date-filters">
          <div className="filter-item">
            <label htmlFor="start-date" className="date-label">Start Date</label>
            <input 
              type="date" 
              id="start-date" 
              className="date-input" 
              value={startDate} 
              onChange={handleStartDateChange}
              min="2024-04-01"  
            />          
            </div>
          <div className="filter-item">
            <label htmlFor="end-date" className="date-label">End Date</label>
            <input type="date" id="end-date" value={endDate} onChange={handleEndDateChange} className="date-input" />
          </div>
        </div>
        <div className="checkbox-item">
          <label htmlFor="show-all">{resultLabel}</label>
          <input type="checkbox" id="show-all" className="checkbox-input" onChange={handleCheckboxChange} checked={showAll}/>
        </div>
        <button className="export-button-medium" title="Export as .xlsx" onClick={onExportClick}>Export</button>
      </div>

      {/* Export Button */}
      <div className="filter-item export-button-container">
        <button className="export-button" title="Export as .xlsx" onClick={onExportClick}>Export</button>
      </div>

      {/* Slider Controls */}
      <div className="slider-controls-small">
        <input
          type="range"
          min="0.5"
          max="8"
          step="0.1"
          value={zoomLevel}
          onChange={onZoomChange}
          className="zoom-slider-small"
          title="Zoom level"
          style={{ width: '100%', marginTop: '10px' }}
        />
        <div className="zoom-factor-small">
          {(Math.round((zoomLevel / 8) * 1000) / 10).toFixed(0)}%
        </div>
      </div>
    </div>
  );
}

export default App;