import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Unsubscribe.css';

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.send('service_zpd614m', 'template_h6rkb4k', { user_email: email }, 'UuoYoxLflsEMlH4Tv')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage("You're officially dead to me.");
      }, (error) => {
        console.error('FAILED...', error);
        setMessage('Failed to unsubscribe. Please try again later.');
      });

    setEmail('');
  };

  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-content">
        <h2 className="unsubscribe-header">Unsubscribe</h2>
        <p>I get it. You don't like me anymore. Enter your email below and you'll never hear from me again.</p>
        <form className="unsubscribe-form" onSubmit={handleSubmit}>
          <label htmlFor="user_email">Email</label>
          <input
            type="email"
            id="user_email"
            name="user_email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button type="submit">Unsubscribe Me</button>
        </form>
        {message && <p className="unsubscribe-message">{message}</p>}
      </div>
    </div>
  );
};

export default Unsubscribe;
