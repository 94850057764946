// resumeData.js
const resumeData = {
    "adriana_kugler": {
        currentRole: {
            role: "Member, Federal Reserve Board of Governors",
            dates: "Since September 13, 2023"
        },
        professional: [
            { role: "US Executive Director, World Bank", dates: "2022 – 2023" },
            { role: "Professor of Public Policy, Georgetown University's McCourt School of Public Policy", dates: "2013 – Present (on leave)" },
            { role: "Vice-Provost for Faculty, Georgetown University", dates: "2013 – 2016" },
            { role: "Chief Economist, US Department of Labor", dates: "2011 – 2013" },
            { role: "Founder and Co-Director, International Summer Institute on Policy Evaluation", dates: "2010 – 2013" },
            { role: "Associate/Full Professor, University of Houston", dates: "2007 – 2013" },
            { role: "Associate Professor, Pompeu Fabra University", dates: "2001 – 2007" },
            { role: "Economist, World Bank", dates: "2001 – 2005" }
        ],
        education: [
            { role: "PhD in Economics, University of California, Berkeley", dates: "1997" },
            { role: "Bachelor's in Economics and Political Science, McGill University", dates: "1991" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Adriana_Kugler",
            "https://isim.georgetown.edu/profile/adriana-kugler/"
        ]
    },
    "alberto_musalem": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of St. Louis",
            dates: "Since April 2, 2024"
        },
        professional: [
            { role: "Adjunct Professor of Finance, Georgetown University's McDonough School of Business", dates: "2022 – Present" },
            { role: "Director, Board of Man Group", dates: "2022 – Present" },
            { role: "Director, Board of Freddie Mac", dates: "2021 – Present" },
            { role: "CEO and Co-CIO, Evince Asset Management LP", dates: "2018 – 2022" },
            { role: "Executive Vice President and Senior Advisor to the President, Federal Reserve Bank of New York", dates: "2014 – 2017" },
            { role: "Chairman of the Board, School the World", dates: "2012 – 2015" },
            { role: "Managing Director, Partner, and Global Head of Research, Tudor Investment Corporation", dates: "2000 – 2013" },
            { role: "Economist, International Monetary Fund", dates: "1996 – 2000" },
            { role: "Adjunct Professor of International Financial Markets, Johns Hopkins' School of Advanced International Studies", dates: "2007" }
        ],
        education: [
            { role: "PhD in Economics, University of Pennsylvania", dates: "1996" },
            { role: "MSc in Economics, London School of Economics", dates: "1992" },
            { role: "BSc in Economics, London School of Economics", dates: "1991" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Alberto_Musalem",
            "https://msb.georgetown.edu/profiles/alberto-musalem/"
        ]
    },
    "austan_goolsbee": {
        currentRole: {
            role: "President, Federal Reserve Bank of Chicago",
            dates: "Since January 9, 2023"
        },
        professional: [
            { role: "Robert P. Gwinn Professor of Economics, University of Chicago's Booth School of Business", dates: "1995 – 2023" },
            { role: "Member, Chicago Board of Education", dates: "2018 – 2019" },
            { role: "Chairman, Council of Economic Advisers (Obama Administration)", dates: "2010 – 2011" },
            { role: "Member, Council of Economic Advisers (Obama Administration)", dates: "2009 – 2010" },
        ],
        education: [
            { role: "PhD in Economics, Massachusetts Institute of Technology", dates: "1995" },
            { role: "MA in Economics, Yale University", dates: "1991" },
            { role: "BA in Economics, Yale University", dates: "1991" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Austan_Goolsbee",
            "https://www.chicagofed.org/utilities/about-us/office-of-the-president/office-of-the-president-home"
        ]
    },
    "christopher_waller": {
        currentRole: {
            role: "Member, Federal Reserve Board of Governors",
            dates: "Since December 18, 2020"
        },
        professional: [
            { role: "Executive Vice President and Director of Research, Federal Reserve Bank of St. Louis", dates: "2009 – 2020" },
            { role: "Professor and Gilbert F. Schaefer Chair of Economics, University of Notre Dame", dates: "2003 – 2009" },
            { role: "Professor and Carol Martin Gatton Chair of Macroeconomics and Monetary Economics, University of Kentucky", dates: "1998 – 2003" },
            { role: "Visiting Scholar, Federal Reserve Bank of St. Louis and Washington University in St. Louis", dates: "1994 – 1995" },
            { role: "Director of Graduate Studies, Associate Professor, and Assistant Professor, Indiana University Department of Economics", dates: "1985 – 1998" },
        ],
        education: [
            { role: "PhD in Economics, Washington State University", dates: "1985" },
            { role: "MA in Economics, Washington State University", dates: "1984" },
            { role: "BS in Economics, Bemidji State University", dates: "1981" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Christopher_Waller",
            "https://www.federalreserve.gov/aboutthefed/bios/board/waller.htm"
        ]
    },
    "jeffrey_schmid": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Kansas City",
            dates: "Since August 21, 2023"
        },
        professional: [
            { role: "President and CEO, Southwestern Graduate School of Banking Foundation, SMU Cox School of Business", dates: "2021 – 2023" },
            { role: "CEO, Susser Bank", dates: "2019 – 2021" },
            { role: "Chairman and CEO, Mutual of Omaha Bank", dates: "2007 – 2019" },
            { role: "President, American National Bank in Omaha, Nebraska", dates: "1989 – 2007" },
            { role: "Field Examiner, Federal Deposit Insurance Corporation (FDIC), Kansas City", dates: "1981 – 1989" }
        ],
        education: [
            { role: "Graduate, Southwestern Graduate School of Banking, SMU", dates: "Unknown" },
            { role: "Bachelor's in Business Administration, University of Nebraska-Lincoln", dates: "Unknown" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Jeffrey_R._Schmid",
            "https://www.kansascityfed.org/about-us/leadership/jeffrey-r-schmid/"
        ]
    },
    "jerome_powell": {
        currentRole: {
            role: "Chair, Federal Reserve",
            dates: "Since February 5, 2018"
        },
        professional: [
            { role: "Member, Federal Reserve Board of Governors", dates: "2012 – 2018" },
            { role: "Visiting Scholar, Bipartisan Policy Center", dates: "2010 – 2012" },
            { role: "Managing Partner, Global Environment Fund", dates: "2008 – 2010" },
            { role: "Founder, Severn Capital Partners", dates: "2005 – 2008" },
            { role: "Partner, The Carlyle Group", dates: "1997 – 2005" },
            { role: "Managing Director, Bankers Trust", dates: "1993 – 1995" },
            { role: "Under Secretary of the Treasury for Domestic Finance", dates: "1992 – 1993" },
            { role: "Vice President, Dillon, Read & Co.", dates: "1984 – 1990" },
            { role: "Attorney, Werbel & McMillen", dates: "1983 – 1984" },
            { role: "Attorney, Davis Polk & Wardwell", dates: "1981 – 1983" },
            { role: "Law Clerk, Judge Ellsworth Van Graafeiland, U.S. Court of Appeals for the Second Circuit", dates: "1979 – 1981" }
        ],
        education: [
            { role: "Juris Doctor, Georgetown University Law Center", dates: "1979" },
            { role: "Bachelor's in Politics, Princeton University", dates: "1975" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Jerome_Powell"
        ]
    },
    "john_williams": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of New York",
            dates: "Since June 18, 2018"
        },
        professional: [
            { role: "President and CEO, Federal Reserve Bank of San Francisco", dates: "2011 – 2018" },
            { role: "Executive Vice President and Director of Research, Federal Reserve Bank of San Francisco", dates: "2002 – 2011" },
            { role: "Economist, Board of Governors of the Federal Reserve System", dates: "1994 – 2002" }
        ],
        education: [
            { role: "PhD in Economics, Stanford University", dates: "1994" },
            { role: "MSc in Economics, London School of Economics", dates: "1989" },
            { role: "BA, University of California, Berkeley", dates: "1984" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/John_C._Williams_(economist)",
            "https://www.newyorkfed.org/research/economists/williams"
        ]
    },
    "kathleen_o'neill": {
        currentRole: {
            role: "Interim President and CEO, Federal Reserve Bank of St. Louis",
            dates: "July 13, 2023 – April 1, 2024"
        },
        professional: [
            { role: "First Vice President and Chief Operating Officer, Federal Reserve Bank of St. Louis", dates: "Unknown – Present" },
            { role: "Executive Vice President, Treasury Division, Federal Reserve Bank of St. Louis", dates: "Unknown" }
        ],
        education: [
            { role: "Executive Leadership Program, Kellogg School of Business, Northwestern University", dates: "Unknown" },
            { role: "Bachelor's in Communications, University of Illinois, Urbana-Champaign", dates: "Unknown" }
        ],
        sources: [
            "https://www.stlouisfed.org/about-us/leadership-governance/bank-officers/executive-bios/kathleen-oneill"
        ]
    },
    "lisa_cook": {
        currentRole: {
            role: "Member, Federal Reserve Board of Governors",
            dates: "Since May 23, 2022"
        },
        professional: [
            { role: "Board Member, Federal Reserve Bank of Chicago", dates: "Jan 2022 - May 2022" },
            { role: "Professor of Economics and International Relations, Michigan State University", dates: "2005 – 2022" },
            { role: "Senior Economist, Council of Economic Advisers, Obama Administration", dates: "2011 – 2012" },
            { role: "National Fellow and Research Fellow, Hoover Institution, Stanford University", dates: "2002 – 2005" },
            { role: "Visiting Assistant Professor, Kennedy School of Government and Harvard Business School", dates: "1997 – 2002" },
            { role: "Senior Adviser on Finance and Development, U.S. Treasury Department", dates: "2000 – 2001" },
        ],
        education: [
            { role: "PhD in Economics, University of California, Berkeley", dates: "1997" },
            { role: "BA in Philosophy, Politics, and Economics, St Hilda's College, Oxford", dates: "1988" },
            { role: "BA in Physics and Philosophy, Spelman College", dates: "1986" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Lisa_D._Cook"
        ]
    },
    "loretta_mester": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Cleveland",
            dates: "June 1, 2014 – June 30, 2024"
        },
        professional: [
            { role: "Executive Vice President and Director of Research, Federal Reserve Bank of Philadelphia", dates: "2010 – 2014" },
            { role: "Senior Vice President and Director of Research, Federal Reserve Bank of Philadelphia", dates: "2000 – 2010" },
            { role: "Economist, Federal Reserve Bank of Philadelphia", dates: "1985 – 2000" },
            { role: "Adjunct Professor of Finance, Wharton School of the University of Pennsylvania", dates: "Unknown" }
        ],
        education: [
            { role: "PhD in Economics, Princeton University", dates: "Unknown" },
            { role: "Masters in Economics, Princeton University", dates: "Unknown" },
            { role: "BA in Mathematics and Economics, Barnard College, Columbia University", dates: "Unknown" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Loretta_J._Mester",
            "https://www.clevelandfed.org/people/profiles/m/mester-loretta-j"
        ]
    },
    "lorie_logan": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Dallas",
            dates: "Since August 22, 2022"
        },
        professional: [
            { role: "Executive Vice President, Federal Reserve Bank of New York", dates: "2012 – 2022" },
            { role: "Manager of the System Open Market Account, Federal Open Market Committee", dates: "Unknown" },
            { role: "Financial Analyst, Federal Reserve Bank of New York", dates: "1999 – Unknown" }
        ],
        education: [
            { role: "Master's in Public Administration, Columbia University", dates: "Unknown" },
            { role: "Bachelor's in Political Science, Davidson College", dates: "Unknown" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Lorie_K._Logan",
            "https://www.dallasfed.org/fed/leadership/logan"
        ]
    },
    "mary_daly": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of San Francisco",
            dates: "Since October 1, 2018"
        },
        professional: [
            { role: "Executive Vice President and Director of Research, Federal Reserve Bank of San Francisco", dates: "2017 – 2018" },
            { role: "Senior Vice President and Associate Director of Research, Federal Reserve Bank of San Francisco", dates: "2013 – 2016" },
            { role: "Group Vice President and Associate Director of Research, Federal Reserve Bank of San Francisco", dates: "2011 – 2013" },
            { role: "Vice President and Head of Microeconomics, Federal Reserve Bank of San Francisco", dates: "2004 – 2011" },
            { role: "Research Advisor, Federal Reserve Bank of San Francisco", dates: "2002 – 2004" },
            { role: "Senior Economist, Federal Reserve Bank of San Francisco", dates: "2000 – 2002" },
            { role: "Economist, Federal Reserve Bank of San Francisco", dates: "1996 – 2000" }
        ],
        education: [
            { role: "PhD in Economics, Syracuse University", dates: "1994" },
            { role: "Master's in Economics, University of Illinois Urbana-Champaign", dates: "1987" },
            { role: "BA in Economics and Philosophy, University of Missouri-Kansas City", dates: "1985" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Mary_C._Daly",
            "https://www.frbsf.org/our-people/leadership/office-of-the-president/"
        ]
    },
    "michael_barr": {
        currentRole: {
            role: "Vice Chair for Supervision, Federal Reserve Board of Governors",
            dates: "Since July 19, 2022"
        },
        professional: [
            { role: "Professor of Public Policy, University of Michigan", dates: "2014 – Present" },
            { role: "Professor of Law, University of Michigan", dates: "2006 – Present" },
            { role: "Dean, Gerald R. Ford School of Public Policy, University of Michigan", dates: "2017 – 2022" },
            { role: "Assistant Secretary of the Treasury for Financial Institutions", dates: "2009 – 2011" },
            { role: "Special Assistant to Secretary Robert Rubin, U.S. Department of the Treasury", dates: "1995 – 1997" },
            { role: "Clerk for Associate Justice David Souter, U.S. Supreme Court", dates: "1993 – 1994" },
            { role: "Clerk for Judge Pierre N. Leval, U.S. District Court for the Southern District of New York", dates: "1992 – 1993" }
        ],
        education: [
            { role: "JD, Yale Law School", dates: "1992" },
            { role: "MPhil in International Relations, Magdalen College, Oxford (Rhodes Scholar)", dates: "1989" },
            { role: "BA in History, Yale College", dates: "1987" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Michael_Barr_(U.S._official)",
            "https://www.federalreserve.gov/aboutthefed/bios/board/barr.htm"
        ]
    },
    "michelle_bowman": {
        currentRole: {
            role: "Member, Federal Reserve Board of Governors",
            dates: "Since November 26, 2018"
        },
        professional: [
            { role: "Kansas State Bank Commissioner", dates: "2017 – 2018" },
            { role: "Vice President, Farmers & Drovers Bank", dates: "2010 – 2017" },
            { role: "Director, The Bowman Group (Public Affairs Consultancy in London)", dates: "2004 – 2010" },
            { role: "Deputy Assistant Secretary and Policy Advisor to Secretary Tom Ridge, Department of Homeland Security", dates: "2003 – 2004" },
            { role: "Director of Congressional and Intergovernmental Affairs, FEMA", dates: "2002 – 2003" },
            { role: "Counsel, US House Committee on Transportation and Infrastructure and Committee on Government Reform and Oversight", dates: "1997 – 2002" }
        ],
        education: [
            { role: "JD, Washburn University School of Law", dates: "1996" },
            { role: "BS in Advertising and Journalism, University of Kansas", dates: "Unknown" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Michelle_Bowman",
            "https://www.federalreserve.gov/aboutthefed/bios/board/bowman.htm"
        ]
    },
    "neel_kashkari": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Minneapolis",
            dates: "Since January 1, 2016"
        },
        professional: [
            { role: "Candidate for Governor of California", dates: "2014" },
            { role: "Managing Director and Head of Global Equities, PIMCO", dates: "2011 – 2013" },
            { role: "Assistant Secretary of the Treasury for Financial Stability", dates: "2008 – 2009" },
            { role: "Interim Assistant Secretary of the Treasury for Financial Stability", dates: "Jul 2008 – Oct 2008" },
            { role: "Assistant Secretary of the Treasury for International Economics and Development", dates: "2008 – 2009" },
            { role: "Special Assistant to the Secretary of the Treasury", dates: "2006 – 2008" },
            { role: "Senior Advisor to the Secretary of the Treasury", dates: "2006 – 2008" },
            { role: "Vice President, Goldman Sachs", dates: "2005 – 2006" },
            { role: "Engineer, TRW Inc.", dates: "1998 – 2002" }
        ],
        education: [
            { role: "MBA, University of Pennsylvania, Wharton School", dates: "2005" },
            { role: "Master's in Mechanical Engineering, University of Illinois at Urbana-Champaign", dates: "1998" },
            { role: "Bachelor's in Mechanical Engineering, University of Illinois at Urbana-Champaign", dates: "1995" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Neel_Kashkari"
        ]
    },
    "patrick_harker": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Philadelphia",
            dates: "Since July 1, 2015"
        },
        professional: [
            { role: "President, University of Delaware", dates: "2007 – 2015" },
            { role: "Dean, Wharton School, University of Pennsylvania", dates: "2000 – 2007" },
            { role: "Deputy Dean, Wharton School, University of Pennsylvania", dates: "1999 – 2000" },
            { role: "Chair, Operations and Information Management Department, Wharton School", dates: "1997 – 1999" },
            { role: "Professor and Chair, Systems Engineering Department, University of Pennsylvania", dates: "1994 – 1996" }
        ],
        education: [
            { role: "PhD in Civil and Urban Engineering, University of Pennsylvania", dates: "1983" },
            { role: "MA in Economics, University of Pennsylvania", dates: "Unknown" },
            { role: "MSE in Civil Engineering, University of Pennsylvania", dates: "Unknown" },
            { role: "BSE in Civil Engineering, University of Pennsylvania", dates: "Unknown" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Patrick_T._Harker",
            "https://www.philadelphiafed.org/our-people/patrick-t-harker"
        ]
    },
    "philip_jefferson": {
        currentRole: {
            role: "Vice Chair, Federal Reserve Board of Governors",
            dates: "Since September 13, 2023"
        },
        professional: [
            { role: "Member, Federal Reserve Board of Governors", dates: "2022 – 2023" },
            { role: "Vice President for Academic Affairs and Dean of Faculty, Davidson College", dates: "2019 – 2022" },
            { role: "Centennial Professor of Economics, Swarthmore College", dates: "1997 – 2019" },
            { role: "Economist, Board of Governors of the Federal Reserve System", dates: "Unknown" },
            { role: "Assistant Professor, Columbia University", dates: "Unknown" }
        ],
        education: [
            { role: "PhD in Economics, University of Virginia", dates: "1990" },
            { role: "MA in Economics, University of Virginia", dates: "Unknown" },
            { role: "BA in Economics, Vassar College", dates: "Unknown" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Philip_Jefferson",
            "https://www.federalreserve.gov/aboutthefed/bios/board/jefferson.htm"
        ]
    },
    "raphael_bostic": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Atlanta",
            dates: "Since June 5, 2017"
        },
        professional: [
            { role: "Chair, Governance, Management, and Policy Process Department, Sol Price School of Public Policy, USC", dates: "2016 – 2017" },
            { role: "Judith and John Bedrosian Chair in Governance and the Public Enterprise, Sol Price School of Public Policy, USC", dates: "2012 – 2017" },
            { role: "Interim Director, Lusk Center for Real Estate, USC", dates: "2015 – 2016" },
            { role: "Assistant Secretary for Policy Development and Research, U.S. Department of Housing and Urban Development (HUD)", dates: "2009 – 2012" },
            { role: "Interim Associate Director, Lusk Center for Real Estate, USC", dates: "2007 – 2009" },
            { role: "Economist, Federal Reserve Board of Governors", dates: "1995 – 2001" }
        ],
        education: [
            { role: "PhD in Economics, Stanford University", dates: "1995" },
            { role: "BA in Economics and Psychology, Harvard University", dates: "1987" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Raphael_Bostic",
            "https://www.atlantafed.org/about/atlantafed/executive-leadership-committee/bostic-raphael"
        ]
    },
    "susan_collins": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Boston",
            dates: "Since July 1, 2022"
        },
        professional: [
            { role: "Provost and Executive Vice President for Academic Affairs, University of Michigan", dates: "2020 – 2022" },
            { role: "Member, Board of Directors, Federal Reserve Bank of Chicago", dates: "2013 – 2022" },
            { role: "Edward M. Gramlich Professor of Public Policy and Professor of Economics, University of Michigan", dates: "2007 – 2022 (on leave)" },
            { role: "Joan and Sanford Weill Dean, Gerald R. Ford School of Public Policy, University of Michigan", dates: "2007 – 2017" },
            { role: "Senior Fellow, Economic Studies Program, Brookings Institution", dates: "2007 – 2017" },
            { role: "Visiting Scholar, International Monetary Fund", dates: "2001" },
            { role: "Member, President's Council of Economic Advisers", dates: "1989 – 1990" }
        ],
        education: [
            { role: "PhD in Economics, Massachusetts Institute of Technology", dates: "1984" },
            { role: "BA in Economics, Harvard University", dates: "1980" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Susan_M._Collins_(economist)",
            "https://www.bostonfed.org/people/bank/susan-m-collins.aspx"
        ]
    },
    "tom_barkin": {
        currentRole: {
            role: "President and CEO, Federal Reserve Bank of Richmond",
            dates: "Since January 1, 2018"
        },
        professional: [
            { role: "Chief Risk Officer, McKinsey & Company", dates: "2015 – 2017" },
            { role: "Senior Partner and Chief Financial Officer, McKinsey & Company", dates: "2009 – 2015" },
            { role: "Chairman, Board of Directors, Federal Reserve Bank of Atlanta", dates: "2013 – 2014" },
            { role: "Member, Board of Directors, Federal Reserve Bank of Atlanta", dates: "2009 – 2014" },
        ],
        education: [
            { role: "JD, Harvard Law School", dates: "1987" },
            { role: "MBA, Harvard Business School", dates: "1987" },
            { role: "Bachelor's in Economics, Harvard University", dates: "1983" }
        ],
        sources: [
            "https://en.wikipedia.org/wiki/Thomas_Barkin",
            "https://www.richmondfed.org/about_us/our_leadership_governance/tom_barkin"
        ]
    }
};

export default resumeData;