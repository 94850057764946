import React, { useState, useEffect, useRef } from 'react';
import blackoutIcon from './Imgs/Zs.png';
import blackoutIconBackground from './Imgs/Zs_background.png';
// import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Define margin constants for the timeline
const MARGIN_LEFT = 175;
const DEFAULT_ZOOM_LEVEL = 8; // Set your default zoom level here

const blackoutPeriods = [
  { start: '2024-01-20', end: '2024-02-01' },
  { start: '2024-03-09', end: '2024-03-21' },
  { start: '2024-04-19', end: '2024-05-01' },
  { start: '2024-06-01', end: '2024-06-09' }, // '2024-06-13'
  { start: '2024-07-20', end: '2024-08-01' },
  { start: '2024-09-07', end: '2024-09-19' },
  { start: '2024-10-26', end: '2024-11-08' },
  { start: '2024-12-07', end: '2024-12-19' },
];

// const throttle = (func, limit) => {
//   let lastFunc;
//   let lastRan;
//   return function () {
//     const context = this;
//     const args = arguments;
//     if (!lastRan) {
//       func.apply(context, args);
//       lastRan = Date.now();
//     } else {
//       clearTimeout(lastFunc);
//       lastFunc = setTimeout(function () {
//         if ((Date.now() - lastRan) >= limit) {
//           func.apply(context, args);
//           lastRan = Date.now();
//         }
//       }, limit - (Date.now() - lastRan));
//     }
//   };
// };


// EventModal component defined to handle multiple events
const EventModal = React.forwardRef(({ events, style, onClose, onClick }, ref) => {

  const navigate = useNavigate();
  
  if (!events.length) return null;  // No events to display

  const handleModalClick = (e) => {
    onClick();    // Trigger the onClick passed as prop
    e.stopPropagation(); // Prevent click from propagating to other elements
  };

  const handleCloseClick = (e) => {
    onClose();
    e.stopPropagation(); // Prevent click from propagating to other elements
  };

  const handleImageClick = (name) => {
    const formattedName = name.toLowerCase().replace(/ /g, '_');
    const url = `/profile/${formattedName}`;
    window.open(url, '_blank');
  };

  // Get the first event's date to use as the header date
  const eventDate = new Date(events[0].date);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',  // 'long', 'short', or 'narrow'
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(eventDate);

  return (
    <div
      ref={ref}
      className="modal-custom"
      style={{ ...style, display: 'block' }}
      tabIndex="-1"
      onClick={handleModalClick}
    >
      <div className="modal-content-wrapper" onClick={handleModalClick}>
        <div className="modal-pointer"></div>
        <div className="modal-header-custom">
          <span>{formattedDate} <span className="event-count" title="Appearance count">&nbsp;({events.length})</span></span>
          <span className="btn-close-custom" title="Close window" onClick={handleCloseClick}>&times;</span>    
        </div>
        <div className="modal-content-container">
          {events.map((event, index) => {
              // Split the remarks on bullet points, filter out empty strings if they exist
              const remarks = event.remark.split(/(•\s)/).filter(line => line.trim() !== '');
              
              return (
                <div key={index} className="modal-content">
                  <div className="modal-image-container">

                    <img 
                      src={event.imageUrl} 
                      alt={event.formattedName}
                      title='View profile'
                      className="modal-image" 
                      onClick={() => handleImageClick(event.formattedName)} 
                    />
                  </div>
                  <div className="modal-body-custom">
                    <div className="modal-name">
                      <a
                        href={`/profile/${event.formattedName.toLowerCase().replace(/ /g, '_')}`}
                        title="View profile"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {event.formattedName}
                      </a>
                      {event.voting_member && 
                        <span className="tooltip" title="FOMC member (as of remarks)">
                          <span className="asterisk">*</span>
                        </span>
                      }
                    </div>       
                    <div className="modal-title">{event.title}</div>
                    <div className="modal-remark">
                      {remarks.map((line, idx) => (
                        <React.Fragment key={idx}>
                        {/* Insert a small line break before each bullet point except the first one */}
                        {idx !== 0 && line.startsWith('•') ? <span style={{ display: 'block', height: '4px' }}></span> : null}
                        {event.url ? <a href={event.url} target="_blank" rel="noopener noreferrer" className="remark-link" title="View source">{line}</a> : line}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
});

function Timeline({ events, showAll, zoomLevel, onZoomChange }) {
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [isGrabbing, setIsGrabbing] = useState(false);
  // const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LEVEL); // Starting zoom level
  const [activeEventGroups, setActiveEventGroups] = useState([]);
  const [openedByClick, setOpenedByClick] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [monthYearLabels, setMonthYearLabels] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [blackoutIconPositions, setBlackoutIconPositions] = useState([]);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [defaultShowAll, setDefaultShowAll] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const timelineRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const modalPositionRef = useRef({});
  const originalPosition = useRef(0); // This will store the initial scroll position when dragging starts
  const PIXELS_PER_DAY = 40; // The base pixels per day before zooming

  useEffect(() => {
    modalPositionRef.current = {};
  }, []);

  // Sync scroll position with slider

  const handleScroll = () => {
    if (timelineRef.current) {
      const newScrollPosition = timelineRef.current.scrollLeft;
      scrollPositionRef.current = newScrollPosition;

      // Check if at the start or end of the timeline
      const atStart = newScrollPosition === 0;
      const atEnd = newScrollPosition >= timelineRef.current.scrollWidth - timelineRef.current.clientWidth;
      setAtStart(atStart);
      setAtEnd(atEnd);

      // Update the position state for each active event group
      setActiveEventGroups(prevGroups => prevGroups.map(group => ({
        ...group,
        dotPosition: calculatePosition(group.date) - newScrollPosition + MARGIN_LEFT,
      })));
    }
  };

  // const handleScroll = useCallback(
  //   throttle(() => {
  //     if (timelineRef.current) {
  //       const newScrollPosition = timelineRef.current.scrollLeft;
  //       scrollPositionRef.current = newScrollPosition;
  
  //       // Check if at the start or end of the timeline
  //       const atStart = newScrollPosition === 0;
  //       const atEnd = newScrollPosition >= timelineRef.current.scrollWidth - timelineRef.current.clientWidth;
  //       setAtStart(atStart);
  //       setAtEnd(atEnd);
  
  //       // Update the position state for each active event group
  //       setActiveEventGroups((prevGroups) =>
  //         prevGroups.map((group) => ({
  //           ...group,
  //           dotPosition: calculatePosition(group.date) - newScrollPosition + MARGIN_LEFT,
  //         }))
  //       );
  //     }
  //   }, 1),
  //   []
  // );

  const smoothScroll = (element, to, duration) => {
    const start = element.scrollLeft;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;
  
    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };
  
    animateScroll();
  };
  
  
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };  

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const scrollToNextRightModal = () => {
    if (timelineRef.current) {
      const scrollLeft = timelineRef.current.scrollLeft;
      const clientWidth = timelineRef.current.clientWidth;
      const modals = Object.keys(modalPositionRef.current).map(key => modalPositionRef.current[key]).filter(Boolean); // Filter out null values
  
      let rightmostModal = null;
      let rightmostModalIndex = -1;
  
      // Identify the rightmost modal in the viewport
      for (let i = 0; i < modals.length; i++) {
        const modalRect = modals[i].getBoundingClientRect();
        if (modalRect.right <= clientWidth && modalRect.right > 0) {
          rightmostModal = modals[i];
          rightmostModalIndex = i;
        }
      }
  
      // Handle edge case: no modals in the viewport
      if (!rightmostModal && modals.length > 0) {
        for (let i = 0; i < modals.length; i++) {
          const modalRect = modals[i].getBoundingClientRect();
          if (modalRect.left > clientWidth || modalRect.right > clientWidth) {
            rightmostModal = modals[i];
            rightmostModalIndex = i;
            break;
          }
        }
      }
  
      if (rightmostModal) {
        const rightmostModalRect = rightmostModal.getBoundingClientRect();
        const rightBorderPosition = rightmostModal.offsetLeft + rightmostModal.offsetWidth;

        // Adjust offset based on browser
        const defaultOffset = 19;
        const safariOffset = 35; // Adjust this value as needed for Safari
        const offset = isSafari ? safariOffset : defaultOffset;
  
        if (rightmostModalIndex === modals.length - 2 && rightmostModalRect.right <= clientWidth) {
          // Scroll all the way to the end if the second-to-last modal's right border is in the viewport
          const newScrollPosition = timelineRef.current.scrollWidth - clientWidth;
          smoothScroll(timelineRef.current, newScrollPosition, 200); // 200ms duration for smoother scroll
        } else if (rightmostModalRect.right <= clientWidth) {
          // If the right border is in the viewport, scroll to the next modal's right border
          if (rightmostModalIndex + 1 < modals.length) {
            const nextModal = modals[rightmostModalIndex + 1];
            const nextModalRect = nextModal.getBoundingClientRect();
            const newScrollPosition = scrollLeft + nextModalRect.left - (clientWidth - nextModalRect.width) + offset;
            smoothScroll(timelineRef.current, newScrollPosition, 200); // 200ms duration for smoother scroll
          }
        } else {
          // Scroll so that the right border of this modal just comes into view
          const newScrollPosition = scrollLeft + rightmostModalRect.left - (clientWidth - rightmostModalRect.width) + offset;
          smoothScroll(timelineRef.current, newScrollPosition, 200); // 200ms duration for smoother scroll
        }
      }
    }
  };
  
  
  const scrollToNextLeftModal = () => {
    if (timelineRef.current) {
      const scrollLeft = timelineRef.current.scrollLeft;
      const clientWidth = timelineRef.current.clientWidth;
      const modals = Object.keys(modalPositionRef.current).map(key => modalPositionRef.current[key]).filter(Boolean); // Filter out null values
  
      let leftmostModal = null;
      let leftmostModalIndex = -1;
  
      // Identify the leftmost modal in the viewport
      for (let i = modals.length - 1; i >= 0; i--) {
        const modalRect = modals[i].getBoundingClientRect();
        if (modalRect.left >= 0 && modalRect.left < clientWidth) {
          leftmostModal = modals[i];
          leftmostModalIndex = i;
        }
      }
  
      // Handle edge case: no modals in the viewport
      if (!leftmostModal && modals.length > 0) {
        for (let i = modals.length - 1; i >= 0; i--) {
          const modalRect = modals[i].getBoundingClientRect();
          if (modalRect.left < 0 || modalRect.right < 0) {
            leftmostModal = modals[i];
            leftmostModalIndex = i;
            break;
          }
        }
      }
  
      if (leftmostModal) {
        const leftmostModalRect = leftmostModal.getBoundingClientRect();
        const leftBorderPosition = leftmostModal.offsetLeft;
  
        if (leftmostModalIndex === 1 && leftmostModalRect.left >= 0) {
          // Scroll all the way to the start if the second modal's left border is in the viewport
          smoothScroll(timelineRef.current, 0, 200); // 200ms duration for smoother scroll
        } else if (leftmostModalRect.left >= 0) {
          // If the left border is in the viewport, scroll to the previous modal's left border
          if (leftmostModalIndex > 0) {
            const previousModal = modals[leftmostModalIndex - 1];
            const previousModalRect = previousModal.getBoundingClientRect();
            const offset = 19; // Adjust this value as needed to ensure the left border is fully in view
            const newScrollPosition = scrollLeft + previousModalRect.left - offset;
            smoothScroll(timelineRef.current, newScrollPosition, 200); // 200ms duration for smoother scroll
          }
        } else {
          // Scroll so that the left border of this modal just comes into view
          const offset = 19; // Adjust this value as needed to ensure the left border is fully in view
          const newScrollPosition = scrollLeft + leftmostModalRect.left - offset;
          smoothScroll(timelineRef.current, newScrollPosition, 200); // 200ms duration for smoother scroll
        }
      } else {
        // If no modals are visible, scroll to the beginning
        smoothScroll(timelineRef.current, 0, 200); // 200ms duration for smoother scroll
      }
    }
  };
  
  
  // Event handler for zoom level changes from the slider
  // const handleZoomChange = (event) => {
  //   setZoomLevel(parseFloat(event.target.value));
  // };

  const onMouseDown = (event) => {
    if (event.target.className === 'zoom-slider') {
      return;
    }
    setDragging(true);
    setIsGrabbing(true);
    originalPosition.current = timelineRef.current.scrollLeft;  // Store the current scroll position
    setStartX(event.clientX);  // Reset startX to just the mouse position
    event.preventDefault();
  };

  const onMouseMove = (event) => {
    if (dragging) {
      const deltaX = event.clientX - startX;  // Difference from the initial mouse down position
      const newScrollPosition = originalPosition.current - deltaX;  // Adjust scroll position based on drag
  
      if (newScrollPosition >= 0 && newScrollPosition <= timelineRef.current.scrollWidth - timelineRef.current.clientWidth) {
        timelineRef.current.scrollLeft = newScrollPosition;  // Set new scroll position if within bounds
      }
    }
  };
  
  const onMouseUp = () => {
    if (dragging) {
      setDragging(false);
      setIsGrabbing(false);
    }
  };

  // This useEffect handles attaching and detaching event listeners
  useEffect(() => {
    if (dragging) {
      // Attach the mousemove and mouseup listeners to the window only when dragging starts
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    } else {
      // Remove the mousemove and mouseup listeners when dragging stops
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }

    // Clean up function to remove event listeners when the component is unmounted or before the next effect runs
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [dragging]); // Only re-run the effect if dragging changes

  // Assume events are already sorted or sort them if necessary
  const sortedEvents = events.length > 0 ? [...events].sort((a, b) => new Date(a.date) - new Date(b.date)) : [];

  // Processed events into groups by date for the timeline
  const groupedEvents = events.reduce((acc, event) => {
    const dateKey = new Date(event.date).toISOString().slice(0, 10); // Format the date as YYYY-MM-DD
    acc[dateKey] = acc[dateKey] || [];
    acc[dateKey].push(event);
    return acc;
  }, {});

  // Event handler for mouse enter on the dot
  const handleMouseEnter = (event) => {
    const eventDate = new Date(event.currentTarget.dataset.date).toISOString().slice(0, 10);  // Convert to YYYY-MM-DD format
    const foundEvents = groupedEvents[eventDate];  // Use the standardized date format for lookup
  
    // Temporarily add event group for hovering, even if 'Show All' is active
    if (foundEvents && !activeEventGroups.some(group => group.date === eventDate)) {
      setActiveEventGroups(prevGroups => [
        ...prevGroups,
        { date: eventDate, events: foundEvents, dotPosition: calculatePosition(eventDate) }
      ]);
    }
  };

  // Event handler for mouse leave on the dot
  const handleMouseLeave = (event) => {
    const eventDate = new Date(event.currentTarget.dataset.date).toISOString().slice(0, 10);  // Convert to YYYY-MM-DD format
    if (!selectedDates.includes(eventDate)) {
      setActiveEventGroups(prevGroups => prevGroups.filter(group => group.date !== eventDate));
    }
  };

  const handleClick = (event) => {
    const eventDate = new Date(event.currentTarget.dataset.date).toISOString().slice(0, 10);
    if (selectedDates.includes(eventDate)) {
      // Close the modal if it's already open
      setSelectedDates(selectedDates.filter(date => date !== eventDate));
      setActiveEventGroups(activeEventGroups.filter(group => group.date !== eventDate));
    } else {
      // Open the modal if it's not already open
      const foundEvents = groupedEvents[eventDate];
      setSelectedDates([...selectedDates, eventDate]);
      setActiveEventGroups([...activeEventGroups.filter(group => group.date !== eventDate), {
        date: eventDate,
        events: foundEvents,
        dotPosition: calculatePosition(eventDate)
      }]);
    }
  };
  
  // Function to handle modal click to bring it to front
  const handleModalClick = (eventDate) => {
    setActiveModal(eventDate);  // Set the clicked modal as the active one
  };
  

  const closeModal = (eventDate) => {
    setActiveEventGroups(prevGroups => prevGroups.filter(group => group.date !== eventDate));
    setSelectedDates(prevDates => prevDates.filter(date => date !== eventDate));
    if (openedByClick && selectedDates.includes(eventDate)) {
      setOpenedByClick(false); // Reset the flag if the closing event is the one opened by click
    }
  };

  const calculatePosition = (date) => {
    if (events.length === 0) return 0;
    const startDate = new Date(events[0].date);
    const eventDate = new Date(date);
    const daysDiff = (eventDate - startDate) / (1000 * 60 * 60 * 24);
    return daysDiff * PIXELS_PER_DAY * zoomLevel;
  };

  // Calculate the start and width for the timeline line
  const firstEventPosition = sortedEvents.length > 0 ? calculatePosition(sortedEvents[0].date) : 0;
  const lastEventPosition = sortedEvents.length > 0 ? calculatePosition(sortedEvents[sortedEvents.length - 1].date) : 0;
  const timelineLineWidth = lastEventPosition - firstEventPosition + (10 / 2); // Adjust according to your logic
  
  const getMonthLabels = (events) => {
    if (events.length === 0) return [];
  
    const startEventDate = new Date(events[0].date);
    startEventDate.setDate(1); // Set to the first of the month
    const endEventDate = new Date(events[events.length - 1].date);
    endEventDate.setMonth(endEventDate.getMonth() + 1, 0); // Set to the end of the month
  
    const monthYearLabels = [];
    let currentDate = startEventDate;
  
    while (currentDate <= endEventDate) {
      const label = currentDate.toLocaleString('default', { month: 'short', year: 'numeric' });
      const position = calculatePosition(currentDate.toISOString());
      monthYearLabels.push({
        label,
        position
      });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  
    return monthYearLabels;
  };

  // Calculate positions for blackout icons
  const calculateBlackoutPositions = (periods, events, calculatePosition, zoomLevel) => {
    if (events.length === 0) return [];
  
    const eventPositions = events.map(event => ({
      date: event.date,
      position: calculatePosition(event.date)
    }));
  
    return periods.flatMap(period => {
      const start = new Date(period.start);
      const end = new Date(period.end);
  
      // Find the event positions just before and after the blackout period
      const beforeBlackout = eventPositions
        .filter(event => new Date(event.date) <= start)
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0]; // Last event before blackout
      const afterBlackout = eventPositions
        .filter(event => new Date(event.date) >= end)
        .sort((a, b) => new Date(a.date) - new Date(b.date))[0]; // First event after blackout
  
      if (!beforeBlackout || !afterBlackout) return [];
  
      const startPosition = beforeBlackout.position;
      const endPosition = afterBlackout.position;
      const totalDistance = endPosition - startPosition;
  
      // Adjust the number of icons based on zoom level
      const minIcons = 1;
      const maxIcons = 5;
      const numberOfIcons = Math.max(minIcons, Math.min(maxIcons, Math.round(zoomLevel * 1.25)));
  
      // Calculate the interval for placing icons
      const interval = totalDistance / (numberOfIcons + 1); // +1 to include space on both ends
  
      const positions = [];
      for (let i = 1; i <= numberOfIcons; i++) {
        const position = startPosition + i * interval;
        positions.push(position);
      }
  
      return positions;
    });
  };

  // Filter blackout periods based on displayed event date range
  const filterBlackoutPeriods = (periods, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    return periods.filter(period => {
      const periodStart = new Date(period.start);
      const periodEnd = new Date(period.end);

      return (periodStart >= start && periodStart <= end) || 
            (periodEnd >= start && periodEnd <= end) || 
            (periodStart <= start && periodEnd >= end);
    });
  };

  // Get date range of displayed events
  const getEventDateRange = (events) => {
    if (events.length === 0) return { startDate: null, endDate: null };

    const sortedEvents = events.sort((a, b) => new Date(a.date) - new Date(b.date));
    const startDate = sortedEvents[0].date;
    const endDate = sortedEvents[sortedEvents.length - 1].date;

    return { startDate, endDate };
  };

  useEffect(() => {
    if (events.length > 0) {
      const newMonthYearLabels = getMonthLabels(sortedEvents);
      setMonthYearLabels(newMonthYearLabels);
    }
  }, [events, zoomLevel]);  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeEventGroups.some(activeGroup => {
        const modalElement = document.getElementById(`modal-${activeGroup.date}`);
        return modalElement && !modalElement.contains(event.target);
      })) {
        setActiveEventGroups([]); // Close all modals if click is outside any modal
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeEventGroups]); // React on changes to activeEventGroups

  // useEffect(() => {
  //   if (showAll) {
  //     // Select all dates and open all modals
  //     const allDates = Object.keys(groupedEvents);
  //     setSelectedDates(allDates);
  //     setActiveEventGroups(allDates.map(date => ({
  //       date,
  //       events: groupedEvents[date],
  //       dotPosition: calculatePosition(date)
  //     })));
  //   } else {
  //     // When unchecking 'Show All', close all modals unless they are individually opened again
  //     setSelectedDates([]);
  //     setActiveEventGroups([]);
  //   }
  // }, [showAll]); // Add groupedEvents as dependency

  useEffect(() => {
    if (defaultShowAll && events.length > 0) {
      const allDates = Object.keys(groupedEvents);
      setSelectedDates(allDates);
      setActiveEventGroups(allDates.map(date => ({
        date,
        events: groupedEvents[date],
        dotPosition: calculatePosition(date)
      })));
      setDefaultShowAll(false); // Ensure this runs only once
    }
  }, [events]); // Only depend on events

  useEffect(() => {
    if (!defaultShowAll) { // Prevent this from running on initial load
      if (showAll) {
        const allDates = Object.keys(groupedEvents);
        setSelectedDates(allDates);
        setActiveEventGroups(allDates.map(date => ({
          date,
          events: groupedEvents[date],
          dotPosition: calculatePosition(date)
        })));
      } else {
        setSelectedDates([]);
        setActiveEventGroups([]);
      }
    }
  }, [showAll]); // Only depend on showAll
  
  useEffect(() => {
    if (isInitialLoad && timelineRef.current && events.length > 0) {
      // Scroll to the end of the timeline
      timelineRef.current.scrollLeft = timelineRef.current.scrollWidth;
      setIsInitialLoad(false); // Ensure this runs only once
    }
  }, [isInitialLoad, events]);  
  

  useEffect(() => {
    const { startDate, endDate } = getEventDateRange(events);
  
    if (startDate && endDate) {
      const filteredBlackoutPeriods = filterBlackoutPeriods(blackoutPeriods, startDate, endDate);
      const blackoutIconPositions = calculateBlackoutPositions(filteredBlackoutPeriods, events, calculatePosition, zoomLevel);
      setBlackoutIconPositions(blackoutIconPositions);
    }
  }, [events, zoomLevel]);

  // Early return if events are not available
  <div style={{ fontSize: '5em', color: 'white' }}>
    { !events || events.length === 0 ? 
      <div>Loading events...</div> 
      : null // Display loading state or handle empty state
    }
  </div>

  return (
    <div className="timeline-wrapper">
      <button
        className={`scroll-arrow left-arrow ${atStart ? 'disabled' : ''}`}
        onClick={scrollToNextLeftModal}
        disabled={atStart}
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 6L9 12L15 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      <button
        className={`scroll-arrow right-arrow ${atEnd ? 'disabled' : ''}`}
        onClick={scrollToNextRightModal}
        disabled={atEnd}
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 6L15 12L9 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      <div
        ref={timelineRef}
        className={`timeline-container ${isGrabbing ? 'grabbing' : ''}`}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        onScroll={handleScroll}
      >
        <div 
          className="timeline-bar" 
          style={{ 
            width: `${timelineLineWidth + MARGIN_LEFT}px`,
            marginLeft: `${MARGIN_LEFT}px` }}>
          <div
            className="timeline-line"
            style={{
              left: '0px',
              width: `${timelineLineWidth}px`
            }}
          ></div>
          {Object.entries(groupedEvents).map(([date, events]) => (
            <div
              key={date}
              className={`timeline-dot ${selectedDates.includes(date) ? 'selected' : ''}`}
              style={{
                left: `${calculatePosition(date)}px`,
                width: `10px`, // Constant size
                height: `10px`, // Constant size
              }}
              data-date={date} // Using index as the identifier for this example
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}  // Handle click to set active event group
              title={date} // Show the event date on hover
            />
          ))}
  
          {/* Render month-year labels within the timeline-bar for correct positioning */}
          {monthYearLabels.map((monthYear, index) => (
            <div
              key={index}
              className="timeline-label"
              style={{
                left: `${monthYear.position}px`,
                top: '100%', // Position below the timeline line
                transform: 'translateY(0)' // Adjust vertical position
              }}
            >
              {monthYear.label}
            </div>
          ))}

          {blackoutIconPositions.map((position, index) => (
            <div
              key={index}
              className="blackout-icon-wrapper"
              style={{ left: `${position}px` }}
              title="FOMC Blackout Period"
            >
              <img
                src={blackoutIconBackground}
                alt="Blackout Period Background"
                className="blackout-icon-background"
              />
              <a href="https://www.federalreserve.gov/monetarypolicy/files/fomc-blackout-period-calendar.pdf" target="_blank" rel="noopener noreferrer">
                <img
                  src={blackoutIcon}
                  alt="Blackout Period Icon"
                  className="blackout-icon-foreground"
                />
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Conditionally render the EventModal component */}
      {sortedEvents.length > 0 && activeEventGroups.map(group => (
        <EventModal
          key={group.date}
          ref={el => modalPositionRef.current[group.date] = el} // Store refs by event.date
          events={group.events}
          onClose={() => closeModal(group.date)}
          onClick={() => handleModalClick(group.date)}
          style={{
            position: 'absolute',
            left: `${calculatePosition(group.date) - scrollPositionRef.current + MARGIN_LEFT - 50}px`,
            transform: 'translateX(-31.5%)', // Center it horizontally on the dot.
            display: 'block', // This ensures that the modal is visible when activeEventGroup is not null.
            zIndex: group.date === activeModal ? 950 : 900 // Conditional zIndex based on active modal
          }}
          id={`modal-${group.date}`} // Unique ID for each modal
        />
      ))}
  
      {/* Render large slider controls for screens larger than 600px */}
      <div className="slider-controls">
        <input
          type="range"
          min="0.5"
          max="8"
          step="0.1"
          value={zoomLevel}
          onChange={onZoomChange}
          className="zoom-slider"
          title="Zoom level"
          style={{ width: '100%', marginTop: '10px' }}
        />
        <div className="zoom-factor">
          {(Math.round((zoomLevel / 8) * 1000) / 10).toFixed(0)}%
        </div>
      </div>

      {/* Render small slider controls for screens 600px and smaller */}

    </div>
  );  
}

export default Timeline;